import React from 'react'
import { useTranslations, useSiteContext } from '@dustin-web/microsite-components/src/hooks'
import Image from '@dustin-web/microsite-components/src/image'
import { getLogosAndLinks, LogosType } from './settings'

const PopularBrands = () => {
  const { translate } = useTranslations()
  const { site } = useSiteContext()
  const data = getLogosAndLinks(site) as Array<LogosType>

  return (
    <div className="u-bg-white c-popular-brands">
      <div className="container u-pt-24 u-pt-lg-32 u-pb-lg-24">
        <h2 className="u-mb-16">{translate('Startpage_PopularBrands')}</h2>
        <div className="row sm-gutters">
          <div className="col-12 d-flex flex-row flex-wrap">
            {data?.map(item => {
              return (
                <div
                  className="c-popular-brands--logo d-flex align-items-center flex-shrink-1 flex-grow-0 flex-md-shrink-0 flex-md-grow-1 justify-content-center col-6 col-sm-3 col-md u-pr-sm-16 u-mb-16 u-mb-md-16 u-mb-lg-0 u-pl-0 u-pr-0"
                  key={`key-${item?.brand}`}>
                  <a
                    href={`${item?.link}`}
                    target="_blank"
                    aria-label={`${item.brand} brand link`}
                    className="d-flex align-items-center justify-content-center">
                    <Image
                      src={item?.logo}
                      width={128}
                      height={62}
                      quality={100}
                      alt={`${item?.brand} logo link`}
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularBrands
