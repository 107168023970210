import { Site } from '@dustin-web/microsite-config'

export type LogosType = {
  brand: string
  logo: string
  link: string
}

const getAppleLogo = (site: Site) => {
  switch (site) {
    case 'DustinFinland':
    case 'DustinHomeFinland':
      return '/content/15378/sbb-fi-apple-logo-208x130.png?width=208&height=130'
    case 'DustinSweden':
    case 'DustinHomeSweden':
      return '/content/14038/sbb-apple-authorized_reseller-logo-208x130.png'
    case 'DustinNorway':
    case 'DustinHomeNorway':
      return '/content/15379/sbb-no-apple-logo-208x130.png?width=208&height=130'
    case 'DustinDenmark':
    case 'DustinHomeDenmark':
      return '/content/15377/sbb-dk-apple-logo-208x130.png?width=208&height=130'
    default:
      return '/content/jm4plrkf/sbb-apple-authorized_reseller-logo-eng-208x130png.png'
  }
}

export const getLogosAndLinks = (site: Site) => {
  switch (site) {
    case 'DustinFinland':
    case 'DustinHomeFinland':
    case 'DustinNorway':
    case 'DustinHomeNorway':
    case 'DustinDenmark':
    case 'DustinHomeDenmark':
    case 'DustinSweden':
    case 'DustinHomeSweden':
      return [
        { brand: 'apple', logo: getAppleLogo(site), link: '/brands/apple' },
        {
          brand: 'cisco',
          logo: '/content/67460/sbb-cisco-logo-208x130-black.png',
          link: '/brands/cisco',
        },
        {
          brand: 'dell technologies',
          logo: '/content/87822/dell-t.png',
          link: '/brands/dell-technologies',
        },
        {
          brand: 'hewlett packard',
          logo: '/content/67463/sbb-hp-logo-208x130-black.png',
          link: '/brands/hp',
        },
        {
          brand: 'hewlett packard enterprise',
          logo: '/content/67462/hp-enterprise-logo-black.png',
          link: '/brands/hewlett-packard-enterprise',
        },
        {
          brand: 'lenovo',
          logo: '/content/78422/lenovo-black-mobile.png',
          link: '/brands/lenovo',
        },
        {
          brand: 'microsoft',
          logo: '/content/9677/sbb-microsoft-logo-208x130.png',
          link: '/brands/microsoft',
        },
      ]
    case 'DustinNetherlands':
    case 'DustinHomeNetherlands':
      return [
        { brand: 'apple', logo: getAppleLogo(site), link: '/handelsmerken/apple' },
        {
          brand: 'cisco',
          logo: '/content/67460/sbb-cisco-logo-208x130-black.png',
          link: '/handelsmerken/cisco',
        },
        {
          brand: 'dell',
          logo: '/content/87822/dell-t.png',
          link: '/handelsmerken/dell',
        },
        {
          brand: 'hewlett packard',
          logo: '/content/67463/sbb-hp-logo-208x130-black.png',
          link: '/handelsmerken/hp',
        },
        {
          brand: 'hewlett packard enterprise',
          logo: '/content/67462/hp-enterprise-logo-black.png',
          link: '/handelsmerken/hewlett-packard-enterprise',
        },
        {
          brand: 'lenovo',
          logo: '/content/78422/lenovo-black-mobile.png',
          link: '/handelsmerken/lenovo',
        },
        {
          brand: 'microsoft',
          logo: '/content/9677/sbb-microsoft-logo-208x130.png',
          link: '/handelsmerken/microsoft',
        },
        {
          brand: 'samsung',
          logo: '/content/67466/sbb-samsung-logo-208x130-black.png',
          link: '/handelsmerken/samsung',
        },
      ]
    default:
      ;[]
  }
}
